/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { Alert, Card, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { buttonStyles, ArtifactWrapper, cardContentStyle } from '../Artifact.styled';
import { truncateText } from './helper';
// import { activeArtifact } from '../ArtifactSlice';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { ToastProps } from '../../../components/Toast/types';
import Toast from '../../../components/Toast/Toast';
import { mapSeverityToAlertColor } from '../../../utils/utils';

interface CategoryDetails {
  name: string;
  id: string;
  createdAt: string;
}

const AddArtifacts: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = React.useState<CategoryDetails[]>([]);
  const [toastmsg, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetch = async () => {
      try {
        const response: any = await CommHandler.request({
          url: `${config.adminApiURL}/artifact/category`,
          method: 'GET',
          params: { status: 1 },
        });
        setCategoryDetails(response.data);
        window.scrollTo({
          top: 0,
          left: 0,
        });
        return response;
      } catch (err) {
        setLoading(false);
        setToastMsg({
          open: true, message: 'Something went wroing', vertical: 'top', horizontal: 'center', severity: 'error',
        });
        return false;
      }
    };
    fetch();
  }, []);

  const handleClickToOpenForm = (key: string, text: string, id: string, categoryCode: string) => {
    const route = `/artifacts/add-artifacts/form/${text}/${id}/${categoryCode}`;
    navigate(route);
  };

  const buttons = categoryDetails && categoryDetails?.map((element: any) => {
    const obj = {
      key: element.name,
      image: ['Individual', 'BU', 'PRO', 'COM', 'SQU', 'TRA'].includes(element.code) ? `/${element.code}.png` : '/Individual.png',
      text: `${element.name}`,
      value: element.id,
      code: element.code,
    };
    return obj;
  });

  useEffect(() => {
    if (categoryDetails && categoryDetails?.length) {
      setLoading(false);
    }
  }, [categoryDetails]);

  if (loading) {
    return (
      <div style={{
        position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  const handClickBack = () => {
    navigate(-1);
  };
  const onDismiss = () => {
    setToastMsg({
      open: false, message: '', horizontal: 'center', vertical: 'top', severity: '',
    });
  };
  return (
    <ArtifactWrapper>
      {toastmsg && (
      <Toast
        open={toastmsg.open}
        autoHideDuration={toastmsg.autoHideDuration}
        message={toastmsg.message}
        vertical={toastmsg.vertical}
        horizontal={toastmsg.horizontal}
        onClose={onDismiss}
      >
        <Alert onClose={onDismiss} severity={mapSeverityToAlertColor(toastmsg.severity)} sx={{ width: '100%' }}>
          {toastmsg.message}
          !
        </Alert>
      </Toast>
      )}
      <Card sx={{ border: '1px solid rgb(231, 231, 231)', borderRadius: '10px', boxShadow: 'none' }}>
        <CardContent style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <div onClick={handClickBack} onKeyPress={(e: { key: string; }) => e.key === 'Enter' && handClickBack()} role="button" tabIndex={0}>
            <img src="/leftArrow.png" alt="left-arrow" width="16px" height="16px" style={{ marginRight: '12px', marginTop: '5px' }} />
          </div>
          <h2 style={cardContentStyle}>Add Artifacts</h2>
        </CardContent>
        {buttons && buttons.length
          ? (
            <DialogContent
              className="dialog-content"
              style={{
                width: '100%', display: 'flex', justifyContent: 'center', borderBottom: 'unset',
              }}
            >
              <div className="button-container">
                {buttons.map((btn) => (
                  <Button
                    autoFocus
                    onClick={() => handleClickToOpenForm(btn.key, btn.text, btn.value, btn.code)}
                    sx={buttonStyles}
                    id={btn.key}
                    style={{ textTransform: 'none', width: '250px', height: '250px' }}
                    key={btn.key}
                  >
                    <div className="button-text-style">
                      <img src={btn.image} alt="Icon" className="button-image" />
                      <div className="button-text">{truncateText(btn.text, 30)}</div>
                    </div>
                  </Button>
                ))}
              </div>
            </DialogContent>
          )
          : (<div style={{ textAlign: 'center', padding: '30px' }}>No Data Found </div>)}
      </Card>
    </ArtifactWrapper>
  );
};

export default AddArtifacts;
