import { io, Socket } from 'socket.io-client';
import { config } from '../config';

const SOCKET_URL = `${config?.socketUrl}`;
const socket: Socket = io(SOCKET_URL);

socket.on('connect', () => {
  console.log('Connected to server');
});

socket.on('sendMessage', (data) => {
  socket.emit('receiveMessage', data, { broadcast: [{ except: [socket?.id], includeself: false }] });
});

socket.on('disconnect', () => {
  console.log('Disconnected from server');
});

export default socket;
