/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  TextField,
  Typography,
  Tooltip,
  Chip,
  Autocomplete,
  Popover,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { config } from '../../../config';
import { CommHandler } from '../../../utils';
import { truncateText } from './helper';
import {
  getAuthHeader, calculateDuration, getCurrentYear, markAsRead,
} from '../../../utils/utils';
import { defaultLevelColumn, projectLevelColumn } from '../../../libs/Constant';
import useFetchAllEmployeeArtifact from '../../../hooks/useFetchAllEmployeeArtifact';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../components/Snackbar/Snackbar';
import DialogBoxforArtifactApproval from './DialogBoxforArtifactApproval';

const ViewArtifactList = (props: any) => {
  const {
    subCategoryName, subCategoryData, tabValue, hasEditAccess, loggedInUserId, categoryDetails, userId, loggedUser, onStatusChange, onReviewerUpdate,
  } = props;
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();

  const [currentPage, setCurrentPage] = useState(0);
  const [visibleData, setVisibleData] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [canApproveOrRejectEnable, setCanApproveOrRejectEnable] = useState(true);
  const [isApprover, setIsApprover] = useState<boolean>(false);
  const projectCategory = categoryDetails?.find((item: { name: string }) => item.name === 'Project');
  const navigate = useNavigate();
  useFetchAllEmployeeArtifact();
  const listUser = useSelector((state: any) => state.employees.list);
  const [addReviewers, SetAddReviewers] = useState<any[]>([]);
  const [searchBoxValue, setSearchBoxValue] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fixedOptions, setFixedOption] = useState([]);
  const [getCurrentartifactId, setCurrentArtifactId] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showRejectMultipleDialog, setShowRejectMultipleDialog] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const actionButtons = [
    {
      icon: <EditIcon />,
      label: 'Edit',
      color: 'primary-icon',
      msg: 'Edit Artifact',
    },
    {
      icon: <ClearIcon />,
      label: 'Reject Selected',
      color: 'primary-icon',
      msg: 'Reject Artifact',
    },
    {
      icon: <CheckIcon />,
      label: 'Approve Selected',
      color: 'primary-icon',
      msg: 'Approve Artifact',
    },
    // {
    // icon: <QuestionAnswerIcon />,
    // label: 'Message',
    // color: 'primary-icon',
    // msg: 'Message-Artifact',
    // },
  ];

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    const addCheckedKey = subCategoryData?.map((item: any) => ({
      ...item,
      isChecked: false,
    }));

    // eslint-disable-next-line radix
    const approve = subCategoryData?.map((element: any) => element.approverList.some((item: any) => parseInt(item.id) === parseInt(loggedInUserId)));
    setVisibleData(addCheckedKey);
    setIsApprover(approve);
    setIsCheckedAll(false);
    setCurrentPage(0);
  }, [subCategoryData, loggedInUserId]);

  const handleCheckboxChange = (key: string, id?: number) => {
    let updatedArtifactState = [];
    if (key === 'all') {
      setIsCheckedAll(!isCheckedAll);
      updatedArtifactState = visibleData?.map((item) => ({
        ...item,
        isChecked: item?.approved === 'pending' && isApprover ? !isCheckedAll : item?.isChecked,
      }));
    } else if (key === 'individual' && id !== undefined) {
      updatedArtifactState = visibleData.map((item) => ({
        ...item,
        isChecked: item.id === id && isApprover ? !item.isChecked : item.isChecked,
      }));
      const allPendingSelected = updatedArtifactState.filter((item) => item?.approved === 'pending').every((item) => item?.isChecked);
      setIsCheckedAll(allPendingSelected);
    }
    setVisibleData(updatedArtifactState);
    const anyCheckboxSelected = updatedArtifactState.some((item) => item.isChecked === true);
    setCanApproveOrRejectEnable(!anyCheckboxSelected);
  };

  const handleStatusChangeAction = async (actionLabel: string, currentArtifactId?: string, isSingleRecordUpdate: boolean = false) => {
    let artifactIDs: string[] = [];
    const UserId = getAuthHeader['x-user_id'];
    const approvedstatus = actionLabel === 'Approve Selected' ? 'approved' : 'rejected';
    const statusLable = actionLabel === 'Approve Selected' ? 'Approved' : 'Rejected';
    if (!isSingleRecordUpdate) {
      artifactIDs = visibleData?.filter((element) => element.isChecked === true && element.approved === 'pending').map((artifactrecords) => artifactrecords.id);
    } else {
      artifactIDs.push(currentArtifactId);
    }
    try {
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/updateStatus`,
        method: 'PUT',
        params: {
          year: currentYear,
        },
        data: { id: artifactIDs, status: approvedstatus, user_id: UserId },
      });
      if (response.status === 200) {
        onStatusChange(approvedstatus);
      } else {
        showErrorSnackbar('Status Change Failed');
      }
    } catch (error) {
      showErrorSnackbar('Something Went Wrong');
    }
  };

  const extractedReviewers = listUser?.map((listData: { user_id: any; fullName: any }) => ({
    id: listData?.user_id,
    name: listData?.fullName,
  }));

  const handleonChatView = async (artifactData?: any, action?: string) => {
    if (action === 'Chat') {
      const artifactFormId = artifactData?.id;
      await markAsRead([artifactFormId]);
      navigate(`/artifacts/viewArtifact/${artifactFormId}`, {
        state: {
          ...artifactData,
          categoryId: artifactData.artifact_category_id,
          userId,
          loggedInUserId,
          loggedUser,
          showChat: true,
        },
      });
    }
  };

  const handleAction = async (artifactData?: any, action?: string) => {
    if (action === 'Edit') {
      const { id } = artifactData;
      const categoryId = artifactData.artifact_category_id;
      navigate(`/artifacts/editArtifact/${id}/${categoryId}`);
    } else if (action === 'View') {
      const artifactFormId = artifactData?.id;
      navigate(`/artifacts/viewArtifact/${artifactFormId}`);
    } else {
      const selectedArtifacts = visibleData.filter((item) => item?.isChecked);
      const selectedArtifactIds = selectedArtifacts.map((item) => String(item?.id));
      for (const artifactId of selectedArtifactIds) {
        await handleStatusChangeAction(action, artifactId, true);
      }
    }
  };

  const handleAddReviewers = (reviewers: any[]) => {
    const finalData = uniqBy(reviewers, 'id');
    setSearchBoxValue(finalData);
    SetAddReviewers(finalData);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>, formatData: any, artifactId: string) => {
    const extractedData = formatData?.map((updatedData: { id: any; name: any }) => ({
      id: updatedData.id,
      name: updatedData.name,
    }));
    const addOption = extractedData?.filter((filterRe: { id: any }) => filterRe.id === loggedInUserId);
    setFixedOption(addOption);
    setSearchBoxValue(extractedData);
    SetAddReviewers(extractedData);
    setAnchorEl(event.currentTarget);
    setCurrentArtifactId(artifactId);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleUpdateReviewer = async (currentArtifactId?: string) => {
    try {
      setIsLoading(true);
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/updateApprover`,
        method: 'PUT',
        data: { id: currentArtifactId, data: addReviewers },
      });
      if (response.status === 200) {
        setIsLoading(false);
        showSuccessSnackbar('Reviewer Updated Successfully!');
        onReviewerUpdate(addReviewers);
      } else {
        showErrorSnackbar('Reviewer Update Failed');
      }
    } catch (error) {
      showErrorSnackbar('Something Went Wrong');
    }
  };

  const handleConfirmCancelConfirmation = async () => {
    setShowConfirmationDialog(false);
    await handleStatusChangeAction(actionData.actionLabel, actionData.currentArtifactId, actionData.isSingleRecordUpdate);
    if (actionData.isSingleRecordUpdate) {
      showSuccessSnackbar(`Artifact ${actionData.actionLabel === 'Approve Selected' ? 'approved' : 'rejected'} successfully!`);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleMultipleArtifacts = async (action: string) => {
    setShowRejectMultipleDialog(false);
    try {
      const selectedArtifacts = visibleData.filter((item) => item.isChecked);
      const selectedArtifactIds = selectedArtifacts.map((item) => String(item.id));
      let successCount = 0;
      const statusLable = action === 'Approve Selected' ? 'Approved' : 'Rejected';

      for (const artifactId of selectedArtifactIds) {
        await handleStatusChangeAction(action, artifactId);
        successCount += 1;
      }
      if (successCount === selectedArtifactIds.length) {
        showSuccessSnackbar(`${selectedArtifactIds.length} artifacts ${statusLable} successfully!`);
      } else {
        showErrorSnackbar(`Error ${action} multiple artifacts`);
      }
    } catch (error) {
      console.error(`Error ${action.toLowerCase()} multiple artifacts:`, error);
    }
  };

  const handleActionClick = async (label: any, ival: any) => {
    setShowConfirmationDialog(true);
    setActionData({
      actionLabel: label,
      currentArtifactId: ival,
      isSingleRecordUpdate: true,
    });
  };

  return (
  // <Grid container spacing={2}>
    <Grid item xs={12} md={12} lg={12}>
      <Box className="artifact-main-table">
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            padding: '10px 12px 2px',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: '24px', fontWeight: 500, lineHeight: '27px', color: 'black',
            }}
            className="certificate-heading"
            gutterBottom
          >
            {subCategoryName}
          </Typography>
        </Box>
        <TabContext value={tabValue!}>
          <TabPanel value={subCategoryName} className="override-tabpanel" key={subCategoryName} />
          <TabPanel value={tabValue!} className="override-tabpanel" />
          <Box className="table-row-item-cell">
            {visibleData.some((item) => item.approverList.some((approver: { id: any }) => parseInt(approver.id) === parseInt(loggedInUserId))
          && item.approved === 'pending') ? (
            <Box className="artifact-table-head">
              <FormControlLabel control={<Checkbox checked={isCheckedAll} onChange={() => handleCheckboxChange('all')} />} label="" />
            </Box>
              ) : (
                <Box className="artifact-table-head" />
              )}
            {tabValue === projectCategory?.id
              ? projectLevelColumn.map((heading: string) => (
                <Box key={heading} className="artifact-table-head">
                  {heading}
                </Box>
              ))
              : defaultLevelColumn.map((heading: string) => (
                <Box key={heading} className="artifact-table-head">
                  {heading}
                </Box>
              ))}
          </Box>
          {visibleData && visibleData.length > 0 ? (
            visibleData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage).map((ival: any) => {
              const uniqueApproverList = ival.approverList.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
              const combinedNames = uniqueApproverList.map((item: { name: any }) => item.name).join(', ');
              const showQuestionAnswerIcon = hasEditAccess || true;
              return (
                <Box className="table-row-item">
                  {isApprover && ival.approverList.some((approver: { id: any }) => parseInt(approver.id) === parseInt(loggedInUserId))
                  && ival.approved === 'pending' ? (
                    <Box className="artifact-table-head">
                      {ival.approved === 'pending'
                    && <FormControlLabel control={<Checkbox checked={ival.isChecked} onChange={() => handleCheckboxChange('individual', ival.id)} />} label="" />}
                    </Box>
                    ) : (
                  // eslint-disable-next-line max-len
                      <Box className="artifact-table-head" />
                    )}

                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        {ival.approved === 'pending' && <Box className="status-pending">Pending</Box>}
                        {ival.approved === 'draft' && <Box className="status-draft">Draft</Box>}
                        {ival.approved === 'approved' && <Box className="status-approved">Approved</Box>}
                        {ival.approved === 'rejected' && <Box className="status-rejected">Rejected</Box>}
                      </ListItem>
                    </List>
                  </Box>
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        <Tooltip title={ival.name} arrow>
                          <ListItemText className="listIem-text" primary={truncateText(ival.name, 40)} />
                        </Tooltip>
                        {' '}
                      </ListItem>
                    </List>
                  </Box>
                  {projectCategory?.id === ival.artifact_category_id && (
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        <ListItemText className="listIem-text" primary={ival.from_date && (ival.to_date || new Date()) ? calculateDuration(ival.from_date, ival.to_date) : ival.from_date} />
                      </ListItem>
                    </List>
                  </Box>
                  )}
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        <Tooltip title={combinedNames} arrow>
                          <ListItemText className="listIem-text" primary={truncateText(combinedNames, 40)} />
                        </Tooltip>
                      </ListItem>
                    </List>
                  </Box>
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        <ListItemText className="listIem-text" primary={ival.approved_by} />
                      </ListItem>
                    </List>
                  </Box>
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem
                        sx={{
                          width: '80px',
                          height: '40px',
                          backgroundColor: '#FDF5EB',
                          borderRadius: '5px',
                          padding: '8px 8px 8px 8px',
                          fontStyle: 'Inter',
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '19.36px',
                          color: '#EDA13B',
                          gap: '8px',
                        }}
                      >
                        <ListItemAvatar sx={{ minWidth: '0px !important' }} />
                        <Avatar
                          src="/Page-1.png"
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <ListItemText className="listIem-text" primary={ival.achieved_points} />
                      </ListItem>
                    </List>
                  </Box>
                  <Box className="artifact-table-head">
                    <List>
                      <ListItem className="list-artifact">
                        <Tooltip title="View Artifact" arrow>
                          <Button sx={{ minWidth: '8px', color: ' #2559C3' }} onClick={() => handleAction(ival, 'View')}>
                            <VisibilityOutlined />
                          </Button>
                        </Tooltip>
                        {showQuestionAnswerIcon && (
                        <Tooltip title="Comments" arrow>
                          <Button
                            sx={{
                              minWidth: '8px',
                              color: ' #2559C3',
                            }}
                            onClick={() => handleonChatView(ival, 'Chat')}
                          >
                            <QuestionAnswerIcon />
                          </Button>
                        </Tooltip>
                        )}
                        {hasEditAccess && (
                        <Tooltip title={actionButtons[0]?.msg} arrow key={actionButtons[0]?.label}>
                          {actionButtons[0]?.label === 'Edit' && ival.approved !== 'approved' && ival.approved !== 'rejected' ? (
                            <Button sx={{ minWidth: '8px', color: '#2559C3' }} onClick={() => handleAction(ival, actionButtons[0]?.label)}>
                              {actionButtons[0]?.icon}
                            </Button>
                          ) : null}
                        </Tooltip>
                        )}
                        {userId
 && isApprover
 && ival.approverList.some((item) => parseInt(item.id) === parseInt(loggedInUserId))
 && ival.approved === 'pending'
 && actionButtons?.slice(1).map((btn) => (
   <Tooltip title={btn.msg} arrow key={btn.label}>
     {ival.approved !== 'approved' && ival.approved !== 'rejected' ? (
       <Button sx={{ minWidth: '8px', color: '#2559C3' }} onClick={() => handleActionClick(btn.label, ival.id)}>
         {btn.icon}
       </Button>
     ) : null}
   </Tooltip>
 ))}
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <Box p={2}>
                            <Autocomplete
                              multiple
                              id="add-reviewer-autocomplete"
                              options={extractedReviewers}
                              value={searchBoxValue}
                              getOptionLabel={(option: any) => option.name}
                              onChange={(_, value) => {
                                if (value.length === 0) {
                                  const newValue = searchBoxValue.filter((option) => fixedOptions.indexOf(option) !== -1);
                                  handleAddReviewers(newValue);
                                } else {
                                  handleAddReviewers(value);
                                }
                              }}
                              renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} disabled={fixedOptions.indexOf(option) !== -1} />)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Reviewers"
                                  variant="outlined"
                                  onKeyDown={(event: any) => {
                                    if (event.key === 'Backspace') {
                                      event.stopPropagation();
                                    }
                                  }}
                                />
                              )}
                            />
                            <Box mt={2} display="flex" justifyContent="space-between">
                              <Button onClick={handleClosePopover} color="primary">
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  handleUpdateReviewer(getCurrentartifactId);
                                }}
                              >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Add'}
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                        {loggedUser?.isSuperAdmin && ival?.approved !== 'approved' && ival?.approved !== 'rejected' && (
                        <Tooltip title="Add Reviewer" arrow>
                          <Button sx={{ minWidth: '8px', color: ' #2559C3' }} onClick={(e) => handleOpenPopover(e, ival?.approverList, ival?.id)}>
                            <AddIcon />
                          </Button>
                        </Tooltip>
                        )}
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box style={{ textAlign: 'center', fontWeight: 400 }}>No data to display</Box>
          )}
          <Dialog className="btn_style_prt" open={showConfirmationDialog}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to
                {' '}
                {actionData?.actionLabel.toLowerCase()}
                {' '}
                artifact ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirmation}>No</Button>
              <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
            </DialogActions>
          </Dialog>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="12px">
            <Box className="pgntn_box">
              <TablePagination
                component="div"
                count={visibleData?.length} // total number of records
                page={currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
            <Box style={{ display: 'flex' }}>
              {visibleData.some((item) => item.approverList.some((approver: { id: any }) => parseInt(approver.id) === parseInt(loggedInUserId)) && item.approved === 'pending')
 && actionButtons?.slice(1).map((item: any) => (
   <Box style={{ padding: '16px 0 0 16px', textAlign: 'right' }}>
     <Grid container spacing={2}>
       <Grid item>
         <Button
           className="secondary-btn"
           variant="outlined"
           disabled={canApproveOrRejectEnable}
           onClick={() => {
             if (item.label === 'Reject Selected') {
               setSelectedAction('Reject Selected');
               setShowRejectMultipleDialog(true);
             } else {
               setSelectedAction('Approve Selected');
               setShowRejectMultipleDialog(true);
             }
           }}
         >
           {item.label}
           {item.icon}
         </Button>
       </Grid>
     </Grid>
   </Box>
 ))}
            </Box>
            <DialogBoxforArtifactApproval showRejectMultipleDialog={showRejectMultipleDialog} setShowRejectMultipleDialog={setShowRejectMultipleDialog} selectedAction={selectedAction} handleMultipleArtifacts={handleMultipleArtifacts} />
          </Box>
        </TabContext>
      </Box>
    </Grid>
  // </Grid>
  );
};
export default ViewArtifactList;
