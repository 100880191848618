/* eslint-disable */
import CommHandler  from "./CommHandler";
import { config } from "../config";

export const checkSuperadminStatus = async (email: string): Promise<boolean> => {
  try {
    const response = await CommHandler.request({
      url: `${config.adminApiURL}/user/checkSuperadmin`,
      method: 'Post',
      data: { email },
    });
    return response.data.isSuperadmin;
  } catch (error) {
    console.log("error", error)
    console.error('Error checking superadmin status:', error);
    return false;
  }
};
