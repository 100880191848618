import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Rankings } from '../../Dashboard/components/Ranking';
import { UserProfiles } from '../../Dashboard/components/UserProfile';
import { StyledCustomizedWrapper } from '../../Dashboard/Dashboard.styled';
import { StyledTotalContainer } from '../BUArtifact.styled';
import ProgressReport from './ProgressReport';
import { getAuthHeader, getCurrentYear } from '../../../utils/utils';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
interface ReactComponentProps {
  categoryDetails: any;
  hideTitle: boolean;
}

const ProfileHeader: React.FC<ReactComponentProps> = ({
  categoryDetails,
  hideTitle = false,
}) => {
  const [rankData, setRankData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { userId: userProfileId } = useParams();
  const userId = getAuthHeader['x-user_id'];
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifactPoints/user-rank`,
          method: 'GET',
          params: userProfileId ? { userId: userProfileId, year: currentYear } : { userId, year: currentYear },
        });
        if (response.data) {
          setRankData(response.data[0]);
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.error('Error fetching rank:', error);
        /* eslint-enable no-console */
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, userProfileId, currentYear]);

  return (
    <StyledCustomizedWrapper
      className="profileHeader"
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <UserProfiles userProfileId={userProfileId} />

          <Box className="progress-profile-bar-report" style={{ maxWidth: '70%' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '24px',
              }}
            >
              <Box
                style={{
                  //   maxHeight: categoryDetails?.length > 6 ? '200px' : 'none',
                  // width: 500,
                  maxHeight: 175,
                }}
              >
                <ProgressReport categoryDetails={categoryDetails} hideTitle={hideTitle} isProfileHeader />
              </Box>
            </Box>

            <Box style={{ display: 'flex' }} className="totalPointsWrapper">
              <Rankings currentBURank={rankData?.currentBURank || 0} currentCompanyRank={rankData?.currentCompanyRank || 0} />
              <StyledTotalContainer className="total-ponits">
                <List
                  sx={{
                    background: '#FDF5EB',
                    textAlign: 'center',
                    padding: '12px',
                    marginRight: '10px',
                  }}
                >
                  <ListItemText>Total Points</ListItemText>
                  <p
                    style={{
                      color: '#EDA13B',
                      fontSize: '24px',
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    {rankData?.totalPoints || 0}
                  </p>
                </List>
              </StyledTotalContainer>
            </Box>
          </Box>
        </>
      )}
    </StyledCustomizedWrapper>
  );
};

export default ProfileHeader;
