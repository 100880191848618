import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse,
} from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from '../config/config';
import { auth } from '../firebase';

const headers: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'x-trace-id': uuidv4(),
};

const CommHandler: AxiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    ...headers,
  },
});

const requestInterceptor = async (request: AxiosRequestConfig) => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  request.headers['x-idToken'] = await auth?.currentUser?.getIdToken() || localStorage.getItem('token');
  request.headers['x-user_id'] = localStorage.getItem('user_id') || '';
  request.headers['x-department'] = localStorage.getItem('department') || '';
  return request;
};
const responseSuccessInterceptor = (response: AxiosResponse) => Promise.resolve(response.data);

const responseErrorInterceptor = (err: AxiosError) => {
  if (err?.response?.data) {
    return Promise.reject(new Error(`${(err.response.data as any).message}`));
  }
  return Promise.reject(new Error('Something went wrong'));
};

CommHandler.interceptors.request.use(requestInterceptor);
CommHandler.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export default CommHandler;
